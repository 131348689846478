import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const scene = {
  namespaced: true,
  state: {
    active: -1,
    default: -1,
    scenes: [],
    deviceScene: {},
    activePageIndex: 0,
    activeViewIndex: -1,
    activeDeviceIndex: -1, // encoder id of camera
    isSceneMode: false,
  },
  getters,
  actions,
  mutations,
};

export default scene;
